import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, Typography, Button } from 'antd'

import Layout from '../../components/layout'
import Head from '../../components/head'
import BetaTestersLogos from '../../components/beta-testers-logos'
import ECPricing from '../../components/ec-pricing'
import ComingTag from '../../components/coming-tag'
import AskDemoButton from '../../components/ask-demo-button'

const { Title, Paragraph, Text } = Typography

const EC = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(relativePath: { eq: "homepage-ec.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      justifsImage: file(relativePath: { eq: "benefice1-ec.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      exportImage: file(relativePath: { eq: "benefice2-ec.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      interfaceImage: file(relativePath: { eq: "rocketchart-alternative-agicap.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      rapideImage: file(relativePath: { eq: "logiciel-tresorerie-rapide.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      checkedImage: file(relativePath: { eq: "checked.png" }) {
        childImageSharp {
          gatsbyImageData(width: 80)
        }
      }
      lionelImage: file(relativePath: { eq: "lionel-hovsepian.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      benoitImage: file(relativePath: { eq: "benoit-maury.png" }) {
        childImageSharp {
          gatsbyImageData(width: 100)
        }
      }
      wcsImage: file(relativePath: { eq: "wcs-europe.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      welybImage: file(relativePath: { eq: "welyb-logo.png" }) {
        childImageSharp {
          gatsbyImageData(height: 35)
        }
      }
      arrowTwoImage: file(relativePath: { eq: "arrow-down-2.png" }) {
        childImageSharp {
          gatsbyImageData(height: 70)
        }
      }
      arrowThreeImage: file(relativePath: { eq: "arrow-down-3.png" }) {
        childImageSharp {
          gatsbyImageData(height: 90)
        }
      }
    }
  `)

  const coverImage = 'https://i.imgur.com/orSvkSu.png'
  const description = 'RocketChart pour les Expert-Comptables : automatisez la pré-comptabilité, gagnez du temps, vendez plus de conseils et explosez la concurrence.'

  return (
    <Layout>
      <Head
        title="Expert-comptable - Logiciel de trésorerie"
        description={description}
        canonical="https://rocketchart.co/solutions/experts-comptables/"
        meta={[
          {
            property: 'og:image',
            content: coverImage
          },
          {
            name: 'twitter:image',
            content: coverImage
          }
        ]}
      >
      </Head>

      {/* because of gutter [40, 40] */}
      <div className="space-around-row" />

      <Row className="content-container space-around-row" gutter={[40, 40]} type="flex" align="middle" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 13, offset: 0, order: 0 }}>
          <Title>Experts-comptables : gagnez du temps, vendez plus de conseils et démarquez-vous !</Title>
          <Paragraph style={{ color: 'black' }}>Comme des dizaines de cabinets en France, simplifiez la pré-comptabilité de vos clients et proposez-leur un logiciel de trésorerie simple et intuitif. Exportez les justificatifs en 1 clic. Démarquez-vous, augmentez vos revenus, développez votre cabinet plus vite, et apportez plus de conseils.</Paragraph>
          <AskDemoButton label='Devenir partenaire' type='primary' />
          <a href="/solutions/experts-comptables#tarifs" style={{ marginLeft: '20px' }}><Button size='large' className="rc-cta">Voir les tarifs</Button></a>
        </Col>
        <Col xs={{ span: 16, offset: 4, order: 0 }} lg={{ span: 11, offset: 0, order: 1 }} justify="center">
          <GatsbyImage image={data.mainImage.childImageSharp.gatsbyImageData} alt="RocketChart pour les Experts-Comptables" className="rounded-image" />
        </Col>
      </Row>

      <Row className="content-container" gutter={40} type="flex" justify="center" style={{ marginTop: '50px' }}>
        <Col span={24} align='center'>
          <Text strong style={{ color: '#6a737d' }}>Ils utilisent déjà RocketChart pour gérer leur trésorerie</Text>
        </Col>
        <BetaTestersLogos />
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '50px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowTwoImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 2" />
          </div>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }} justify="center">
          <Paragraph align='center'>
            <GatsbyImage image={data.justifsImage.childImageSharp.gatsbyImageData} alt="Upload justificatifs sur RocketChart" />
          </Paragraph>
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Ne courez plus après les justificatifs de vos clients</Title>
          <Paragraph style={{ color: 'black' }}>Avec votre accès comptable, accédez aux opérations bancaires et aux justificatifs de paiement réconciliés. Vos clients effectuent la réconciliation en toute autonomie directement depuis leur interface.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Identifiez d’un coup d’oeil les pièces manquantes et relancez vos clients facilement pour les récupérer. Faites gagnez du temps à vos équipes comptables et.gatsbyImageDataifiez la relation avec vos clients.</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 0 }} justify="center">
          <ComingTag />
          <Title level={2}>Gagnez du temps sur la pré-comptabilité</Title>
          <Paragraph style={{ color: 'black' }}>Définissez vos règles d’allocation de comptes pour automatiser la saisie dans le plan comptable général. Vos clients conservent leur vue en trésorerie analytique. Ils ne sont pas perdus dans l’analyse des chiffres.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Contrôlez la catégorisation effectuée et exportez les informations en 1 clic. Intégrer les données dans vos outils de production comptable. Dégagez du temps à vos équipes pour qu’elles deviennent plus productives.</Paragraph>
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 1 }} justify="center">
          <GatsbyImage image={data.exportImage.childImageSharp.gatsbyImageData} alt="Export comptable RocketChart" className="rounded-image" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 0 }} justify="center">
          <GatsbyImage image={data.interfaceImage.childImageSharp.gatsbyImageData} alt="Dashboard RocketChart" className="rounded-image rc-basic-shadow" />
        </Col>
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 1 }}>
          <Title level={2}>Apportez plus de visibilité et conseils à vos clients</Title>
          <Paragraph style={{ color: 'black' }}>Les entrepreneurs se tournent de plus en plus vers leurs comptables pour obtenir des conseils business. Saisissez cette opportunité pour développer votre cabinet. Utilisez RocketChart pour créer des discussions proactives et booster la relation avec vos client.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Offrez-leur un suivi en temps réel de leur trésorerie, construisez facilement des prévisions fiables et accompagnez-les dans leurs choix stratégiques. Vos clients bénéficient d'une vue claire et précise de leur business, et vous avez une nouvelle offre à facturer pour augmenter vos revenus !</Paragraph>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" align="middle">
        <Col xs={{ span: 24, order: 0 }} lg={{ span: 12, order: 0 }} justify="center">
          <Title level={2}>Différenciez-vous de la concurrence et augmentez vos revenus</Title>
          <Paragraph style={{ color: 'black' }}>D’après l’entreprise Xero, 52% des cabinets comptables veulent offrir plus de conseils à leurs clients, mais seulement 11% le font réellement. Aussi, les cabinets qui s’appuient sur des outils innovants comme RocketChart augmentent leurs revenus 60% plus vite que les autres.</Paragraph>
          <Paragraph style={{ color: 'black' }}>Positionnez votre cabinet à part et démarquez-vous de la concurrence en proposant à vos clients un outil innovant pour gérer et anticiper facilement la trésorerie.</Paragraph>
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 1 }} justify="center">
          <GatsbyImage image={data.rapideImage.childImageSharp.gatsbyImageData} alt="Différenciez vous avec RocketChart" className="rounded-image rc-basic-shadow" />
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex">
        <Col xs={24} lg={8} align="middle">
          <Row className='flex items-center'>
            <Col xs={4} lg={24}>
              <GatsbyImage image={data.checkedImage.childImageSharp.gatsbyImageData} alt="Checked" />
            </Col>
            <Col xs={20} lg={24}>
              <Title level={4}>Licence gratuite pour votre cabinet</Title>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8} align="middle">
          <Row className='flex items-center'>
            <Col xs={4} lg={24}>
              <GatsbyImage image={data.checkedImage.childImageSharp.gatsbyImageData} alt="Checked" />
            </Col>
            <Col xs={20} lg={24}>
              <Title level={4}>Offre spéciale pour vos clients</Title>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8} align="middle">
          <Row className='flex items-center'>
            <Col xs={4} lg={24}>
              <GatsbyImage image={data.checkedImage.childImageSharp.gatsbyImageData} alt="Checked" />
            </Col>
            <Col xs={20} lg={24}>
              <Title level={4}>Période d’essai gratuit</Title>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '60px', marginBottom: '40px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} md={16} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Vendez plus de conseils, gagnez du temps et explosez la concurrence</Title>
          <AskDemoButton label='Devenir partenaire' type='primary' />
          <Link to="/solutions/experts-comptables#tarifs" style={{ marginLeft: '20px' }}><Button size='large' className="rc-cta">Voir les tarifs</Button></Link>
        </Col>
      </Row>

      <Row className="content-container space-around-row" gutter={40} type="flex" justify="center">
        <Col xs={{ span: 24 }} align='center'>
          <Title level={2}>Avis clients</Title>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
              <GatsbyImage image={data.wcsImage.childImageSharp.gatsbyImageData} alt="logo WCS" />
            </div>
            <Title level={4}>« Je maîtrise enfin l'évolution de ma tréso au doigt et à l’oeil grâce à Rocketchart. Chaque mouvement est auto-catégorisé, je gère mon cash-flow en temps réel et réalise mes prévisions en ajoutant des simulations. Chez WCS Europe, nous appelons ça : l’innovation digitale au coeur de la performance. »</Title>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GatsbyImage image={data.lionelImage.childImageSharp.gatsbyImageData} alt="Lionel Hovsepian - Président & Co-fondateur - WCS Europe" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '10px' }}>
                <Text strong>Lionel Hovsepian</Text>
                <Text>Président & Co-fondateur - WCS Europe</Text>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} lg={12}>
          <div className="rc-quote-container" style={{ marginTop: 20, marginBottom: 20 }}>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
              <GatsbyImage image={data.welybImage.childImageSharp.gatsbyImageData} alt="logo Welyb" />
            </div>
            <Title level={4}>« RocketChart est un outil hyper intuitif me permettant de suivre ma tréso en temps réel. L'affectation des opérations est très simple et sa fonctionnalité d'export des données me permet de mettre à jour très facilement mon business plan sur Google Sheet. J'adore ! »</Title>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <GatsbyImage image={data.benoitImage.childImageSharp.gatsbyImageData} alt="Benoît Maury - Co-fondateur & CEO - Welyb" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '10px' }}>
                <Text strong>Benoît Maury</Text>
                <Text>Co-fondateur & CEO - Welyb</Text>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row id='tarifs' className="content-container space-around-row" gutter={40} type="flex" justify="center" style={{ marginBottom: '60px' }}>
        <Col xs={24} align='center' className='mt-24 mb-4'>
          <Title level={2}>Tarifs cabinet partenaire</Title>
          <Paragraph style={{ color: 'black' }}>Offre spéciale partenaire RocketChart - Engagement annuel - Période d’essai gratuit</Paragraph>
        </Col>

        <Col xs={24} lg={8} className='mt-8'>
          <ECPricing title="1 - 10 clients" subtitle="20% de remise" price={79} />
        </Col>

        <Col xs={24} lg={8} className='mt-8'>
          <ECPricing title="10 - 50 clients" subtitle="30% de remise" price={69} />
        </Col>

        <Col xs={24} lg={8} className='mt-8'>
          <ECPricing title="+50 clients" subtitle="40% de remise" price={59} />
        </Col>
      </Row>

      <Row className="content-container" type="flex" justify="center" style={{ marginTop: '60px', marginBottom: '60px' }}>
        <Col xs={24} md={16}>
          <div align='center'>
            <GatsbyImage image={data.arrowThreeImage.childImageSharp.gatsbyImageData} align='center' alt="arrow down 3" />
          </div>
        </Col>
        <Col xs={24} md={16} align='center' style={{ marginTop: '20px' }}>
          <Title level={2}>Vendez plus de conseils, gagnez du temps et démarquez-vous</Title>
          <AskDemoButton label='Devenir partenaire' type='primary' />
        </Col>
      </Row>

    </Layout>
  )
}

export default EC
