import React from "react"
import PropTypes from 'prop-types'
import { Card } from "antd"
import AskDemoButton from "./ask-demo-button"

const ECPricing = ({ title, subtitle, price }) => {
  return (
    <Card
      className='rc-ec-pricing-card'
      title={
        <div>
          <div className='rc-large-text rc-bold-text'>{title}</div>
          <div className='rc-medium-text'>{subtitle}</div>
        </div>
      }
    >
      <div className='rc-medium-text text-black'>
        <div className='mt-2'><span className='font-bold'>Inclus</span> toutes les fonctionnalités</div>
        <div className='mt-2'><span className='font-bold'>Inclus</span> accès expert-comptable</div>
        <div className='mt-2'><span className='font-bold'>Inclus</span> 2 banques par clients<br />(+10€ /mois /suppl.)</div>
        <div className='mt-2'><span className='font-bold'>Inclus</span> 2 user par clients<br />(+5€ /mois /suppl.)</div>
      </div>

      <div className='text-center rc-bold-text text-black mt-2'>
        <span className='rc-very-large-text'>{price}€</span>
        <span className='rc-large-text'> /mois</span>
      </div>

      <div className='rc-medium-text text-black text-center mb-4'>Par client</div>

      <div className='text-center'>
        <AskDemoButton label='Devenir partenaire' type='primary' />
      </div>
    </Card>
  )
}

ECPricing.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.number
}

export default ECPricing
